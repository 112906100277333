<template>
  <div class="UserNotificationsViewAll">
    <a href="#"
       class="link"
       @click.prevent="$emit('click')">View All</a>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
  .UserNotificationsViewAll {
    @include flex-center;

    width: 100%;
    height: 40px;

    padding: 10px;
    border-top: 1px solid rgb(212, 218, 231);
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    background-color: #f8f8f8;

    .link {
      font-weight: 600;
    }
  }
</style>
