<template>
  <div class="NavigationSidebar">
    <div class="header">
      <router-link :to="{ name: 'home' }" class="logo">
        <img src="/images/logo-white.svg">
        <!-- <span class="name">{{ appName }}</span> -->
      </router-link>

      <div class="close" @click="$emit('close')">
        <icon-cross width="32" height="32" color="#fff" />
      </div>
    </div>

    <desktop-menu />
  </div>
</template>

<script>
import DesktopMenu from '@/components/Layouts/Dashboard/Navigation/NavigationDesktopMenu.vue'

export default {
  components: {
    DesktopMenu
  },

  data () {
    return {
      appName: process.env.VUE_APP_BRAND_NAME
    }
  }
}
</script>

<style lang="scss" scoped>
  .NavigationSidebar {
    position: fixed;
    z-index: 100;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100vw;
    max-width: 240px;
    min-height: 100vh;
    padding: 10px;

    overflow-y: auto;

    color: #fff;
    background-color: $sidebar-background-color;

    transition: 0.1s width ease-in;

    .header {
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;
      margin-left: 6px;
    }

    .logo {
      display: inline-flex;
      align-items: center;
      flex: 1 0;

      img {
        height: 30px;
      }

      .name {
        margin-left: 10px;
        color: #fff;
        font-size: 20px;
        font-weight: 700;
        letter-spacing: 1px;
      }
    }

    .close {
      flex-shrink: 0;
      // margin-right: 10px;
    }
  }
</style>
