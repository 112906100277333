<template>
  <div class="MonitorOnline"
       :class="classes">
    <div class="header">
      <slot name="title"
            class="title">
        Notification Title
      </slot>
      <div class="date">
        {{ date | shortDate }}
      </div>
    </div>
    <slot name="description"
          class="description"></slot>
  </div>
</template>

<script>
export default {
  props: {
    notification: {
      required: true,
      type: Object
    }
  },

  computed: {
    monitor () {
      return this.notification.data.monitor
    },

    date () {
      return this.notification.created_at
    },

    classes () {
      return {
        unread: !this.notification.read_at
      }
    },

    monitorRoute () {
      return {
        name: 'monitors.single',
        params: {
          id: this.monitor.id
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .MonitorOnline {
    padding: 10px;
    margin: 6px 0;

    &.unread {
      background-color: #fffaf0;
    }

    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .title {
        font-size: 14px;
        font-weight: 600;
      }

      .date {
        font-size: 12px;
      }
    }

    .description {
      color: #999ca7;
    }

    .url:not(:hover) {
      color: #999ca7
    }
  }
</style>
