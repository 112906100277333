<template>
  <aside class="Navigation" :class="[sidebar]" @click.stop>
    <transition enter-active-class="animate__animated animate__fadeIn animate__faster"
                v-if="sidebar === 'tablet'">
      <navigation-tablet :user="user" />
    </transition>
    <transition enter-active-class="animate__animated animate__fadeIn animate__faster"
                v-else>
      <navigation-desktop :user="user"/>
    </transition>
  </aside>
</template>

<script>
import NavigationTablet from './NavigationTablet.vue'
import NavigationDesktop from './NavigationDesktop.vue'

export default {
  components: {
    NavigationTablet,
    NavigationDesktop
  },

  methods: {
    ...mapMutations('userPreferences', [
      'setSidebar'
    ])
  },

  computed: {
    ...mapState('userPreferences', [
      'sidebar'
    ]),

    ...mapState('authentication', [
      'user'
    ])
  }
}
</script>

<style lang="scss" scoped>
  .Navigation {
    position: fixed;
    left: 0;
    top: 0;
    height: 100vh;
    min-width: 74px;
    z-index: 10;

    color: #fff;
    background-color: $sidebar-background-color;

    &.desktop {
      transition: 0.1s width ease-in;
      width: 250px;
    }

    &.tablet {
      transition: 0.1s width ease-out;
      width: 60px;
    }
  }
</style>
