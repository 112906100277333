<template>
  <div class="DashboardHeaderUser">
    <user-notifications />
    <user-profile />
  </div>
</template>

<script>
import UserNotifications from './UserNotifications/UserNotifications.vue'
import UserProfile from './HeaderUserProfile.vue'

export default {
  components: {
    UserNotifications,
    UserProfile
  }
}
</script>

<style lang="scss" scoped>
  .DashboardHeaderUser {
    display: flex;
    align-items: center;
    height: 100%;
  }
</style>
