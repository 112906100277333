<template>
  <div class="NavigationDesktop">
    <div class="inner">
      <router-link :to="{ name: 'home' }" class="logo">
        <img src="/images/logo-white.svg">
        <!-- <span class="name">{{ appName }}</span> -->
      </router-link>
      <desktop-menu />
    </div>

    <navigation-toggle v-if="showToggle" />
  </div>
</template>

<script>
import NavigationToggle from './NavigationToggle.vue'
import DesktopMenu from './NavigationDesktopMenu.vue'

export default {
  components: {
    NavigationToggle,
    DesktopMenu
  },

  props: {
    user: {
      type: Object
    },
    showToggle: {
      required: false,
      type: Boolean,
      default: true
    }
  },

  data () {
    return {
      appName: process.env.VUE_APP_BRAND_NAME
    }
  }
}
</script>

<style lang="scss" scoped>
  .NavigationDesktop {
    height: 100%;

    .inner {
      display: flex;
      flex-direction: column;
      overflow: auto;
      height: 100%;
      width: 100%;
      padding: 20px 10px 0;
    }

    .logo {
      display: flex;
      align-items: center;
      margin-bottom: 28px;
      margin-left: 13px;

      img {
        height: 30px;
      }

      .name {
        margin-left: 10px;
        color: #fff;
        font-size: 20px;
        font-weight: 700;
        letter-spacing: 1px;
      }
    }
  }
</style>
