<template>
  <div class="Dashboard" @click="closeSidebar" :class="classes">
    <dashboard-header ref="dashboardHeader" />

    <dashboard-navigation v-if="$mq === 'desktop'"/>

    <div class="content" :class="$mq">
      <router-view class="container" />
    </div>
    <div class="dm-short-menu">
        <div class="circle">
          <svg class="dm-short-menu-logo" width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="30" cy="30" r="28" fill="white"></circle><path d="M20 32.24C22.8498 32.24 25.16 29.9298 25.16 27.08C25.16 24.2302 22.8498 21.92 20 21.92C17.1502 21.92 14.84 24.2302 14.84 27.08C14.84 29.9298 17.1502 32.24 20 32.24Z" fill="#6652e0"></path><path d="M30 0C13.44 0 0 13.44 0 30C0 46.56 13.44 60 30 60C46.56 60 60 46.56 60 30C60 13.44 46.56 0 30 0ZM9.4 27.12C9.4 21.28 14.16 16.52 20 16.52C25.84 16.52 30.6 21.28 30.6 27.12C30.6 32.96 25.84 37.72 20 37.72C14.16 37.72 9.4 32.96 9.4 27.12ZM31.36 48.52C28.04 48.52 24.84 47.6 22.04 45.84L24.8 41.4C26.76 42.64 29.04 43.28 31.36 43.28C33.68 43.28 35.96 42.64 37.88 41.4L40.64 45.84C37.88 47.6 34.64 48.52 31.36 48.52ZM35.72 29.72V24.48H51.8V29.72H35.72Z" fill="#6652e0"></path></svg>
        </div>
        <div class="dm-short-menu-window">
            <span class="nobr">Hey there 👋 &nbsp;Friends</span> from <a href="https://designmodo.com" class="link-hover inline" target="_blank">designmodo</a> are here to help!
            <div class="link-box">
                <a href="https://designmodo.com/products/" class="dm-short-menu-link" target="_blank">More Products</a>
                <a class="dm-short-menu-link" @click="openBeacon">Start a Chat...</a>
            </div>
        </div>
        <span class="dm-short-menu-badge"></span>
    </div>
  </div>
</template>

<script>
import DashboardHeader from './DashboardHeader/Header.vue'
import DashboardNavigation from './Navigation/Navigation.vue'
import Swal from 'sweetalert2'
import store from '@/store'

export default {
  components: {
    DashboardHeader,
    DashboardNavigation
  },

  created () {
    if (this.google_and_traditional_account) {
      this.showTraditionalAndGoogleAccountWarning()
    }
  },

  methods: {
    ...mapMutations('userPreferences', [
      'setSidebar'
    ]),
    closeSidebar () {
      if (this.$refs.dashboardHeader) {
        this.$refs.dashboardHeader.closeSidebar()
      }
    },
    openBeacon () {
      window.Beacon('open')
      let beaconPopup = document.getElementById('beacon-container')
      setTimeout(() => {
        let beaconInner = null
        if (beaconPopup) {
          beaconPopup = document.getElementById('beacon-container')
          beaconInner = beaconPopup.getElementsByClassName('Beacon')[0]
        }
        if (!beaconPopup || !beaconInner?.innerHTML) {
          window.Beacon('init', 'f9e84fd2-f54f-4b3f-a38b-a540e8793bed')
          window.Beacon('open')
        }
      }, 100)
    },

    showTraditionalAndGoogleAccountWarning () {
      Swal.fire({
        title: 'Information',
        text: 'The email address you provided is already associated with an existing account in our system. We recommend changing the password/enabling two-factor authentication.',
        showClass: {
          popup: ''
        },
        hideClass: {
          popup: ''
        },
        confirmButtonColor: '#6652e0',
        allowOutsideClick: true
      }).then(() => {
        store.commit('authentication/setGoogleAndTraditionalAccount', false)
      }
      )
    }
  },
  computed: {
    classes () {
      if (this.$mq !== 'desktop') {
        return []
      }

      return {
        [`sidebar-` + this.sidebar]: true
      }
    },

    ...mapState('authentication', [
      'user', 'google_and_traditional_account'
    ]),

    ...mapState('userPreferences', [
      'sidebar'
    ])
  }
}

// helpscout
/* eslint-disable */
!function(e,t,n){function a(){var e=t.getElementsByTagName("script")[0],n=t.createElement("script");n.type="text/javascript",n.async=!0,n.src="https://beacon-v2.helpscout.net",e.parentNode.insertBefore(n,e)}if(e.Beacon=n=function(t,n,a){e.Beacon.readyQueue.push({method:t,options:n,data:a})},n.readyQueue=[],"complete"===t.readyState)return a();e.attachEvent?e.attachEvent("onload",a):e.addEventListener("load",a,!1)}(window,document,window.Beacon||function(){});

window.Beacon('init', 'f9e84fd2-f54f-4b3f-a38b-a540e8793bed')
</script>

<style lang="scss" scoped>
  .Dashboard {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    background-color: map-get($colors, background);

    .content {
      display: flex;
      flex-direction: column;
      flex: 1 0;
      margin-top: 60px;
      padding-top: 40px;

      &.desktop {
        padding: 90px 0 20px 74px;
      }
    }

    &.sidebar-desktop .content {
      padding: 40px 0 20px 250px;
    }
  }
</style>
