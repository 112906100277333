<template>
  <div class="Navigation" @click.stop>
    <icon-burger-menu width="24" height="24" @click="show = true" />
    <transition enter-active-class="animate__animated animate__slideInLeft"
                leave-active-class="animate__animated animate__slideOutLeft">
      <NavigationSidebar v-if="show" @close="show = false"/>
    </transition>
  </div>
</template>

<script>
import NavigationSidebar from './NavigationSidebar.vue'

export default {
  components: {
    NavigationSidebar
  },

  data () {
    return {
      show: false
    }
  },

  methods: {
    closeSidebar () {
      this.show = false
    }
  }
}
</script>

<style lang="scss" scoped>
  .Navigation {
    @include flex-center;

    padding: 10px 10px 10px 0;

    .animate__slideInLeft,
    .animate__slideOutLeft {
      animation-duration: 200ms;
    }
  }
</style>
