<template>
  <div class="NavigationToggle">
    <icon-navigation-expand
      v-if="sidebar === 'tablet'"
      width="24"
      height="24"
      @click="setSidebar('desktop')"
    />
    <icon-navigation-collapse
      v-else
      width="24"
      height="24"
      @click="setSidebar('tablet')"
    />
  </div>
</template>

<script>
export default {
  methods: {
    ...mapMutations('userPreferences', [
      'setSidebar'
    ])
  },

  computed: {
    ...mapState('userPreferences', [
      'sidebar'
    ])
  }
}
</script>

<style lang="scss">
  .NavigationToggle {
    position: absolute;
    right: -12px;
    top: calc(50% - 24px);
    z-index: 100;
    cursor: pointer;
  }
</style>
