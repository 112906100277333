<template>
  <div>
    <div v-if="!user.email_verified_at" style="display: flex; margin-left: 40px;">
       <a v-if="!loading" href="#" @click.prevent="resendConfirmationEmail()" class="text">👉 &nbsp;Resend the confirmation email.</a>
      <base-loader-dots v-else class="ml-5" />
    </div>
  </div>
</template>

<script>
import tippy from 'tippy.js'
import auth from '../../../../../services/auth'
import Swal from 'sweetalert2'
import BaseLoaderDots from '../../../../Base/BaseLoaderDots'

export default {
  components: { BaseLoaderDots },
  data () {
    return {
      loading: false
    }
  },

  mounted () {
    if (this.$refs.hint) {
      tippy(this.$refs.hint, {
        content: this.$refs.dropdown,
        interactive: true,
        theme: 'light-border',
        animation: 'shift-away-subtle',
        placement: 'bottom',
        arrow: true,
        inertia: true,
        trigger: 'click',
        maxWidth: 550
      })
    }
  },

  methods: {
    async resendConfirmationEmail () {
      this.loading = true
      try {
        await auth.resendConfirmationEmail()
        this.showSuccessMessage()
      } catch {
        this.showErrorMessage()
      }
      this.loading = false
    },

    showSuccessMessage () {
      Swal.fire({
        title: 'Success',
        text: 'An email with your request will be sent to ' + this.user.email,
        showClass: {
          popup: ''
        },
        hideClass: {
          popup: ''
        },
        confirmButtonColor: '#6652e0',
        allowOutsideClick: true
      })
    },

    showErrorMessage () {
      Swal.fire({
        title: 'Oops',
        text: 'Something went wrong ',
        showClass: {
          popup: ''
        },
        hideClass: {
          popup: ''
        },
        allowOutsideClick: true
      })
    }
  },

  computed: {
    ...mapState('authentication', [
      'user'
    ])
  }
}
</script>

<style lang="scss" scoped>
  .usage-hint {
    display: flex;
    margin-left: 40px;
    cursor: pointer;

    .text {
      padding-bottom: 2px;
      border-bottom: 1px solid map-get($colors, gray-3);
    }

    &:hover .text {
      border-bottom: 1px solid map-get($colors, gray-2);
    }
  }

  .dropdown {
    padding: 10px;

    .title {
      margin-bottom: 10px;
      font-weight: bold;
    }

    ol {
      padding-left: 14px;
      margin: 0;

      li {
        padding: 4px;
      }
    }

    .text-underline {
      text-decoration: underline;
    }
  }
</style>
