<template>
  <div class="UserNotificationsList">
    <div class="empty" v-if="notifications.length === 0">
      You don't have any notifications yet
    </div>

    <notification-item v-for="notification in notifications"
                       :key="notification.id"
                       :notification="notification">
      <template v-slot:title>
        {{ notification.type | notificationType }}
      </template>
      <template v-slot:description>
        <notification-description :notification="notification" />
      </template>
    </notification-item>
  </div>
</template>

<script>
import NotificationItem from './UserNotificationsListItem.vue'
import NotificationDescription from '@/components/Common/NotificationDescription/NotificationDescription.vue'

export default {
  components: {
    NotificationItem,
    NotificationDescription
  },

  props: {
    notifications: {
      required: true,
      type: Array
    }
  }
}
</script>

<style lang="scss" scoped>
  .UserNotificationsList {
    height: 300px;
    overflow: auto;

    .empty {
      @include flex-center;

      width: 100%;
      height: 100%;

      font-size: 16px;
      color: #999ca7;
    }
  }
</style>
