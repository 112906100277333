<template>
  <div class="NavigationTablet">
    <div class="inner">
      <router-link :to="{ name: 'home' }" class="logo">
        <img src="/images/logo.svg"  alt=""/>
      </router-link>
      <nav class="menu">
        <section class="section">
          <router-link :to="{ name: 'monitors.list' }" ref="monitors" class="nav-item">
            <icon-pulse width="24" height="24" color="#fff" />
          </router-link>
          <router-link :to="{ name: 'statusPages.list' }" ref="statusPages" class="nav-item">
            <icon-status-page width="24" height="24" color="#fff" />
          </router-link>
          <router-link :to="{ name: 'BadgesPage' }" ref="badgesPage" class="nav-item">
            <icon-status-badge width="24" height="24" color="#fff" />
          </router-link>
        </section>
        <section class="section">
          <router-link :to="{ name: 'account.profile' }" ref="profile" class="nav-item nav-item-exact">
            <icon-account width="24" height="24" color="#fff" />
          </router-link>
          <router-link v-if="user.role === 'admin'" :to="{ name: 'account.subscription' }"
                       ref="subscription" class="nav-item nav-item-exact">
            <icon-credit-card width="24" height="24" color="#fff" />
          </router-link>
          <router-link v-if="user.role === 'admin'" :to="{ name: 'account.usage' }"
                       ref="usage" class="nav-item nav-item-exact">
            <icon-tick width="24" height="24" color="#fff" />
          </router-link>
          <router-link v-if="user.role === 'admin'" :to="{ name: 'account.teammates' }"
                       ref="teammates" class="nav-item nav-item-exact">
            <icon-teammates width="24" height="24" color="#fff" />
          </router-link>
          <router-link v-if="user.role === 'admin'" :to="{ name: 'account.api' }"
                       ref="api" class="nav-item nav-item-exact">
            <icon-api width="24" height="24" color="#fff" />
          </router-link>
        </section>
      </nav>
    </div>
    <navigation-toggle />
  </div>
</template>

<script>
import NavigationToggle from './NavigationToggle.vue'
import tippy from 'tippy.js'
import IconTick from '../../../Icons/IconTick'

export default {
  components: {
    IconTick,
    NavigationToggle
  },

  mounted () {
    // When using vue-tippy (v-tippy directive) the hint placement
    // was wrong, so we use tippy directly, event though
    // looks like there is some room for refactoring

    tippy(this.$refs.monitors.$el, {
      content: 'Monitors',
      placement: 'right'
    })

    tippy(this.$refs.statusPages.$el, {
      content: 'Status Pages',
      placement: 'right'
    })

    tippy(this.$refs.badgesPage.$el, {
      content: 'Status Badges',
      placement: 'right'
    })

    tippy(this.$refs.profile.$el, {
      content: 'Profile',
      placement: 'right'
    })

    tippy(this.$refs.subscription.$el, {
      content: 'Subscription',
      placement: 'right'
    })

    tippy(this.$refs.usage.$el, {
      content: 'Usage',
      placement: 'right'
    })

    tippy(this.$refs.teammates.$el, {
      content: 'Teammates',
      placement: 'right'
    })
    tippy(this.$refs.api.$el, {
      content: 'API',
      placement: 'right'
    })
  },

  computed: {
    ...mapState('authentication', ['user'])
  }

}
</script>

<style lang="scss" scoped>
  .NavigationTablet {
    height: 100%;

    .inner {
      display: flex;
      flex-direction: column;
      overflow: auto;
      height: 100%;
      width: 100%;
      padding: 14px;
    }

    .logo {
      padding: 6px;
      margin-bottom: 13px;

      img {
        width: 34px;
      }
    }

    .menu {
      display: flex;
      flex-direction: column;
      flex: 1 0;
    }

    .section {
      margin-bottom: 30px;
    }

    .nav-item {
      @include flex-center;

      padding: 10px;
      margin: 10px 0;
      border-radius: 8px;
      text-decoration: none;
      color: #fff;

      &:hover:not(.router-link-active) {
        color: #fff;
        background-color: #2f2b36;
      }

      &.router-link-active {
        color: #fff;
        background-color: map-get($colors, purple-1);
      }

      .label {
        margin-left: 12px;
      }
    }

    .section-bottom {
      margin-top: auto;
    }
  }
</style>
