<template>
  <div class="Notifications">
    <notification-icon ref="icon"
                       :number="unreadNotificationsNumber" />

    <div ref="dropdown" class="dropdown">
      <div class="dropdown-content">
        <header class="header">
          <div class="title">Notifications</div>
          <a href="#"
             @click.prevent="markAllAsRead"
             class="mark-as-read">Mark all as read</a>
        </header>

        <notification-list :notifications="user.notifications"/>
      </div>

      <view-all @click="goToAll"/>
    </div>
  </div>
</template>

<script>
import tippy from 'tippy.js'
import NotificationIcon from './UserNotificationsIcon.vue'
import NotificationList from './UserNotificationsList.vue'
import ViewAll from './UserNotificationsViewAll.vue'

import profileApi from '@/api/profileApi.js'

export default {
  components: {
    NotificationIcon,
    NotificationList,
    ViewAll
  },

  data () {
    return {
      tippyInstance: null
    }
  },

  mounted () {
    this.initDropdown()
  },

  methods: {
    initDropdown () {
      this.tippyInstance = tippy(this.$refs.icon.$el, {
        content: this.$refs.dropdown,
        interactive: true,
        theme: 'light-border no-padding',
        animation: 'shift-away-subtle',
        placement: 'bottom',
        arrow: true,
        inertia: true,
        trigger: 'click',
        maxWidth: 500
      })
    },

    goToAll () {
      this.tippyInstance.hide()
      this.$router.push({
        name: 'account.notifications'
      })
    },

    async markAllAsRead () {
      this.tippyInstance.hide()

      await profileApi.markNotificationsAsRead()

      this.refreshCurrentUser()
    },

    ...mapActions('authentication', [
      'refreshCurrentUser'
    ])
  },

  computed: {
    unreadNotificationsNumber () {
      return this.user.unread_notifications_number
    },

    ...mapState('authentication', [
      'user'
    ])
  }
}
</script>

<style lang="scss" scoped>
  .Notifications {
    height: 26px;

    .dropdown {
      width: 400px;

      .dropdown-content {
        padding: 10px 10px 0 10px;
      }

      .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 6px;

        .title {
          color: #999ca7;
          font-size: 12px;
          font-weight: 700;
          text-transform: uppercase;
        }
      }
    }
  }
</style>
