<template>
  <div class="UserProfile">
    <div class="avatar" ref="avatar">
      <img :src="require('@/assets/images/account.svg')" height="30" width="30" />
      <icon-dropdown width="20"
                     height="20"
                     class="dropdown-icon"
                     color="#8f95e6" />
    </div>

    <div ref="dropdown">
      <nav class="user-nav">
        <router-link :to="{ name: 'account.profile' }"
                     class="nav-item"
                     @click.native="closeDropdown">Profile</router-link>
        <router-link v-if="user.role === role.Admin" :to="{ name: 'account.subscription' }"
                     class="nav-item"
                     @click.native="closeDropdown">Subscription</router-link>
        <a href="#"
           class="nav-item"
           @click="onLogoutClick">Log out</a>
      </nav>
    </div>
  </div>
</template>

<script>
import auth from '@/services/auth.js'
import tippy from 'tippy.js'
import { Role } from '@/_helpers/role.js'

export default {
  data () {
    return {
      tippyInstance: null,
      role: Role
    }
  },

  mounted () {
    this.initDropdown()
  },

  computed: {
    ...mapState('authentication', [
      'user'
    ])
  },

  methods: {
    initDropdown () {
      this.tippyInstance = tippy(this.$refs.avatar, {
        content: this.$refs.dropdown,
        interactive: true,
        theme: 'light-border',
        animation: 'shift-away-subtle',
        placement: 'bottom',
        arrow: true,
        inertia: true,
        trigger: 'click'
      })
    },

    closeDropdown () {
      this.tippyInstance.hide()
    },

    onLogoutClick () {
      auth.logout()
      window.location.href = '/'
    }
  }
}
</script>

<style lang="scss" scoped>
  .UserProfile {
    height: 30px;
    margin-left: 30px;

    .avatar {
      display: flex;
      align-items: center;
      cursor: pointer;

      .dropdown-icon {
        margin-left: 6px;
      }
    }

    .user-nav {
      width: 150px;

      .nav-item {
        display: block;
        padding: 8px 10px;
        color: map-get($colors, gray-1);
        font-size: 15px;
        text-decoration: none;
        font-weight: 500;
        border-radius: 4px;

        &:hover {
          background-color: map-get($colors, purple-1);
          color: #fff;
        }
      }
    }
  }
</style>
