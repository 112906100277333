<template>
  <div class="UserNotificationsIcon">
    <icon-notification-filled width="26"
                       height="26"
                       color="#8f95e6"/>
    <div class="number" v-if="number > 0">
      {{ number }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    number: {
      required: false,
      type: Number,
      default: 0
    }
  }
}
</script>

<style lang="scss" scoped>
  .UserNotificationsIcon {
    position: relative;
    height: 100%;

    cursor: pointer;

    .number {
      position: absolute;
      left: 0;
      top: 0;

      @include flex-center;

      padding: 2px 6px 1px;
      margin: -6px 0 0 10px;

      background-color: #ed4f32;
      border-radius: 12px;

      font-size: 10px;
      font-weight: 600;
      line-height: 15px;
      color: #fff;
    }
  }
</style>
