<template>
  <nav class="NavigationDesktopMenu">
    <section class="section">
      <router-link v-for="item in topLinks" :key="item.name" :to="{ name: item.name }" class="nav-item">
        <component :is="item.icon" width="24" height="24" color="#fff"></component>
        <div class="label">{{ item.title }}</div>
      </router-link>
    </section>
    <section class="section">

      <template v-for="item in bottomLinks">
        <router-link
          v-if="!item.isAdmin || user.role === 'admin' || (item.isEditor && user.role === 'editor')"
          :key="item.name"
          :to="{ name: item.name }"
          class="nav-item"
        >
          <component :is="item.icon" width="24" height="24" color="#fff"></component>
          <div class="label">{{ item.title }}</div>
        </router-link>
      </template>
    </section>

    <!-- <section class="section-bottom">
      <a href="/changelog"
         class="nav-item"
         target="_blank">
        Changelog ↗
      </a>
    </section> -->
  </nav>
</template>

<script>
import { Role } from '@/_helpers/role'
import IconTick from '../../../Icons/IconTick'

export default {
  components: { IconTick },

  data () {
    return {
      role: Role,
      topLinks: [
        {
          name: 'monitors.list',
          icon: 'icon-pulse',
          title: 'Monitors'
        },
        {
          name: 'statusPages.list',
          icon: 'icon-status-page',
          title: 'Status Pages'
        },
        {
          name: 'BadgesPage',
          icon: 'icon-status-badge',
          title: 'Status Badges'
        }
      ],
      bottomLinks: [
        {
          name: 'account.profile',
          icon: 'icon-account',
          title: 'Profile',
          isAdmin: false
        },
        {
          name: 'account.subscription',
          icon: 'icon-credit-card',
          title: 'Subscription',
          isAdmin: true
        },
        {
          name: 'account.usage',
          icon: 'icon-tick',
          title: 'Usage',
          isAdmin: true
        },
        {
          name: 'account.teammates',
          icon: 'icon-teammates',
          title: 'Teammates',
          isAdmin: true
        },
        {
          name: 'account.api',
          icon: 'icon-api',
          title: 'API',
          isAdmin: true,
          isEditor: true
        }
      ]
    }
  },

  computed: {
    ...mapState('authentication', ['user'])
  }

}
</script>

<style lang="scss" scoped>
  .NavigationDesktopMenu {
    display: flex;
    flex-direction: column;
    flex: 1 0;

    .section {
      margin-bottom: 30px;
    }

    .nav-item {
      display: flex;
      align-items: center;
      padding: 10px 12px;
      margin: 10px 0;
      border-radius: 8px;
      text-decoration: none;
      color: #fff;
      font-size: 16px;
      font-weight: 500;

      &:hover:not(.router-link-active) {
        color: #fff;
        background-color: #2f2b36;
      }

      &.router-link-active {
        color: #fff;
        background-color: map-get($colors, purple-1);
      }

      .label {
        margin-left: 12px;
      }
    }

    .section-bottom {
      margin-top: auto;
    }
  }
</style>
